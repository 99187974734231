import './app.scss';

import './components/404/404';
import './components/contact/contact';
import './components/footer/footer';
import './components/front-page/front-page';
import './components/nav/nav';
import './components/page/page';
import './components/taxonomy-locations/taxonomy-locations';
import './components/single/single';
